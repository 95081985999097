import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
Vue.use(axios)

const store = new Vuex.Store({
  state: {
    isAuthenticated: !!localStorage.getItem('token'),
    token: localStorage.getItem('token') || '',
    user: {},
    custVehicle: 0,
    customers: 0,
    inventory: 0,
    processing: 0,
    transit: 0,
    arrived: 0,
    archive: 0,
    titleIssues: 0,
    invoice: 0,
    unpaidInvoices: 0,
    paidInvoices: 0,
    keywords: 0,
    jsonData: [],
    originalJsonData: [],
    unreadMessagesCount: 0,
    unreadNotificationsCount: 0,
    unreadMessageCount: 0,
    notifications: []
  },
  mutations: {
    authSuccess (state, payload) {
      state.user = payload.user
      state.token = payload.token
      state.isAuthenticated = true
    },

    authLogout (state, payload) {
      state.isAuthenticated = false
      localStorage.removeItem('token')
    },

    dashboardData (state, payload) {
      state.custVehicle = payload.custVehicle
      state.customers = payload.customers
      state.inventory = payload.inventory
      state.processing = payload.processing
      state.transit = payload.transit
      state.arrived = payload.arrived
      state.archive = payload.archive
      state.titleIssues = payload.titleIssues
      state.invoice = payload.invoice
    },

    searchVehicle (state, payload) {
      state.jsonData = payload.searchResult
      state.originalJsonData = payload.searchResult
      state.keywords = payload.keywords
    }
  },

  actions: {
    login ({ commit }, user) {
      this.axios({
        method: 'post',
        url: '/api/login',
        data: {
          email: user.email,
          password: user.password
        }
      }).then(response => {
        localStorage.setItem('token', response.data.token)
        user = response.data.user
        let token = response.data.token
        commit('authSuccess', token, user)
      }).catch(error => {
        localStorage.removeItem('token')
        console.log(error.response)
      })
    },

    logout ({ commit }) {
      axios({
        method: 'post',
        url: '/api/logout'
      }).then(res => {
        localStorage.removeItem('token')
      }).catch(error => {
        console.log(error.response)
      })
    },

    async getDashboardData ({ commit }) {
      await axios({
        method: 'get',
        url: '/api/dashboard'
      }).then(response => {
        const custVehicle = response.data.custVehicle
        const customers = response.data.customers
        const inventory = response.data.inventory
        const processing = response.data.process
        const transit = response.data.transit
        const arrived = response.data.arrived
        const archive = response.data.archive
        const titleIssues = response.data.titleIssues
        const invoice = response.data.invoice
        commit('dashboardData', { custVehicle, customers, inventory, processing, transit, arrived, archive, titleIssues, invoice })
      })
    },

    searchVehicleAction ({ commit }, keywords) {
      axios.get('/api/vehicle/search/' + keywords)
        .then(res => {
          const searchResult = res.data.cars
          commit('searchVehicle', { searchResult, keywords })
        }).catch(error => {
          console.log(error)
        })
    }
  },
  getters: {

  }
})

export default store
