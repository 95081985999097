import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/containers',
  component: LayoutHorizontalSidenav,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/containers/Containers.vue')
    },
    {
      path: 'docs/:id',
      component: () => import('@/components/containers/ContainerDocs.vue'),
      name: 'ContainerDocs',
      props: true
    },
    {
      path: 'docs/upload/:id',
      component: () => import('@/components/containers/UploadContainerDocs.vue'),
      name: 'UploadContainerDocs',
      props: true
    },
    {
      path: 'photos/:id',
      component: () => import('@/components/containers/ContainerPhotos.vue'),
      name: 'ContainerPhotos',
      props: true
    },
    {
      path: 'photos/upload/:id',
      component: () => import('@/components/containers/UploadContainerPhotos.vue'),
      name: 'UploadContainerPhotos',
      props: true
    },
    {
      path: 'showContainer/:id',
      component: () => import('@/components/containers/ShowContainer.vue'),
      name: 'ShowContainer',
      props: true
    }

  ]
}]
