import Layout2 from '@/layout/Layout2'


export default [{
    path:'/requests',
    component:Layout2,
    meta:{requiresAuth:true},
    children:[
        {
            path:'',
            component:()=>import('@/components/requests/Requests.vue'),
        },
        {
            path:'create',
            component:()=>import('@/components/requests/CreateRequest.vue'),
        },

        {
            path:'singleRequest/:id',
            component:()=>import('@/components/requests/SingleRequest.vue'),
            name:'SingleRequest',
            props:true,
        }
        
       
    ]
}]