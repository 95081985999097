import Layout2 from '@/layout/Layout2'

export default [{
    path:'/invoices',
    component:Layout2,
    meta:{requiresAuth:true},
    children:[
        {
          path: 'unpaid',
          component: () => import('@/components/invoices/UnpaidInvoices')
        },
        {
          path: 'paid',
          component: () => import('@/components/invoices/PaidInvoices')
        },
        
    ]
}]
