import Layout2 from '@/layout/Layout2'


export default [{
    path:'/customers',
    component:Layout2,
    meta:{requiresAuth:true},
    children:[
        {
            path:'list',
            component:()=>import('@/components/customers/Customers'),
        },
        {
            path:'vehicles',
            component:()=>import('@/components/customers/Vehicles'),
        },
        {
            path:'create',
            component:()=>import('@/components/customers/CreateCustomer'),
            name:'createCustomer',
        },
        {
            path:'edit/:id',
            component:()=>import('@/components/customers/EditCustomer'),
            name:'editCustomer',
            props:true,
        },
    ]
}]
