import Layout2 from '@/layout/Layout2'

export default [{
    path:'/',
    component:Layout2,
    meta:{requiresAuth:true},
    children:[
        {
            path:'inventory',
            component:()=>import('@/components/cars/Inventory.vue'),
        },
        {
            path:'process',
            component:()=>import('@/components/cars/Process.vue'),
        },
        {
            path:'transit',
            component:()=>import('@/components/cars/Transit.vue'),
        },
        {
            path:'arrived',
            component:()=>import('@/components/cars/Arrived.vue'),
        },
        {
            path:'archive',
            component:()=>import('@/components/cars/Archive.vue'),
        },

        {
            path:'searchResult',
            component:()=>import('@/components/cars/SearchResult.vue'),
            name:'SearchResult',
            // query:{keywords:q},
            props:true,
        },

        {
            path:'vehicle/:id',
            component: () => import('@/components/cars/VehicleDetails.vue'),
            name:'VehicleDetails',
            props:true,
        },

        {
            path:'container/:id',
            component: () => import('@/components/cars/LoadedContainer.vue'),
            name:'Container',
            props:true,
        }
        // {
        //     path:'create',
        //     component:()=>import('@/components/cars/crud/CreateCar.vue'),
        //     name:'createCar',
        // },
        // {
        //     path:'edit/:id',
        //     component:()=>import('@/components/cars/crud/EditCar.vue'),
        //     name:'editCar',
        //     props:true,
        // }
    ]
}]