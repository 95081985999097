import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  // Blank layout
  path: '/',
  component: LayoutBlank,
  children: [
        {
            path: 'home',
            component:()=>import('@/components/home/Index'),
            name:'Home'
        }
    ]
}]
