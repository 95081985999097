import Layout2 from '@/layout/Layout2'
import Layout2Flex from '@/layout/Layout2Flex'


export default [{
    path:'/messages',
    component:Layout2Flex,
    meta:{requiresAuth:true},
    children:[
        {
            path:'',
            component:()=>import('@/components/messages/Messages'),
            name:'Messages',
        },
        // {
        //     path:'create',
        //     component:()=>import('@/components/customers/CreateCustomer'),
        //     name:'createCustomer',
        // },
        // {
        //     path:'edit/:id',
        //     component:()=>import('@/components/customers/EditCustomer'),
        //     name:'editCustomer',
        //     props:true,
        // },

        
    ]
}]
