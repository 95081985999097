import Layout2 from '@/layout/Layout2'


export default [{
    path:'/accounts',
    component:Layout2,
    meta:{requiresAuth:true},
    children:[
        {
            path:'',
            component:()=>import('@/components/accounts/CustAccount.vue'),
        },

        // {
        //     path:'transactions/:id',
        //     component:()=>import('@/components/accounts/Transaction.vue'),
        //     name:'transactions',
        //     props:true,
        // }
        // {
        //     path:'create',
        //     component:()=>import('@/components/customers/CreateCustomer'),
        //     name:'createCustomer',
        // },
        // {
        //     path:'edit/:id',
        //     component:()=>import('@/components/customers/EditCustomer'),
        //     name:'editCustomer',
        //     props:true,
        // },
    ]
}]
