<template>
<div>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x">
    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand to="/overview" class="app-brand d-lg-none py-2 mr-4">
      <span class="app-brand-text demo font-weight-normal ml-2">Zemmita Group</span>
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2">

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <label class="nav-item navbar-text navbar-search-box pl-2 py-0 active" style="background:#ebeef4;color:#000;width:400px;border-radius:4px;">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input id="search-bar" name="search" type="text" class="form-control navbar-text mx-2 px-3 search" placeholder="Search..." autocomplete="off"
             v-model="keywords" value="" @keyup.enter="generalSearch" style="background:#ebeef4;color:#000;width:400px;border-radious:4px;">
          </span>
        </label>
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">
        <b-nav-item-dropdown no-caret :right="!isRTL" class="demo-navbar-notifications mr-lg-3" ref="navDropdownNotification" >
          <template slot="button-content">
            <i class="lnr lnr-alarm navbar-icon align-middle text-primary"></i>
            <b-badge pill variant="info indicator" v-if="$store.state.unreadNotificationsCount > 0">
              <strong>{{this.$store.state.unreadNotificationsCount}}</strong>
            </b-badge>
            <!-- <span v-if="notificationToDisplay.length > 0" class="badge badge-primary badge-dot indicator"></span> -->
            <!-- <span v-if="unreadCount > 0" class="badge badge-primary indicator">{{unreadCount}}</span> -->
            <span class="d-lg-none align-middle">&nbsp; Notifications</span>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">
            <span v-if="$store.state.unreadNotificationsCount > 0">{{this.$store.state.unreadNotificationsCount}} New Notifications </span>
            <span v-else>You have no unread notifications </span> 
          </div>
          <b-list-group flush style="max-height:400px; overflow-x: hidden;">
            <!-- Notifications -->
            <b-list-group-item :v-if="$store.state.unreadNotificationsCount > 0 " href="javascript:void(0)" class="media d-flex align-items-center" 
              v-for="notification in notificationToDisplay" 
              :key="notification.id" :class="{'bg-light':notification.read_at === null}" 
              @click ="goTo(notification)" >
              
              <div v-if="notification.data.notification_type == 'photo'" class="ui-icon ui-icon-sm ion ion-ios-images bg-primary border-0 text-white"></div>
              <div v-if="notification.data.notification_type == 'process'" class="ui-icon ui-icon-sm ion ion-ios-car bg-danger border-0 text-white"></div>
              <div v-if="notification.data.notification_type == 'container'" class="ui-icon ui-icon-sm fas fa-ship bg-success border-0 text-white"></div>
              <div v-if="notification.data.notification_type == 'document'" class="ui-icon ui-icon-sm ion ion-ios-book bg-warning border-0 text-white"></div>
              <div v-if="notification.data.notification_type == 'invoice'" class="ui-icon ui-icon-sm fas fa-file-invoice-dollar bg-warning border-0 text-white"></div>
              <div class="media-body ml-3">
                <div class="line-height-condenced" >{{notification.data.content}}</div>
                <!-- <div class="text-light small mt-1">
                  Saad
                </div> -->
                <div class="text-light small mt-1">
                  <!-- {{moment().duration().hours()}} -->
                </div>
              </div>
            </b-list-group-item>
            <!-- Notifications -->

          </b-list-group>

          <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1" @click="showAllNotification">Show all notifications</a>
        </b-nav-item-dropdown>
<!-- 
        <b-nav-item-dropdown no-caret :right="!isRTL" class="demo-navbar-messages mr-lg-3">
          <template slot="button-content">
            <i class="ion ion-ios-mail navbar-icon align-middle" @click="showMessages"></i>
            <b-badge pill variant="success indicator" v-if="this.$store.state.unreadMessagesCount != 0 ">
              <strong>{{this.$store.state.unreadMessagesCount}}</strong>
            </b-badge>
          </template>

          <div class="bg-primary text-center text-white font-weight-bold p-3">
            4 New Messages
          </div>
          <b-list-group flush>
            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
              <img :src="`${baseUrl}img/avatars/6-small.png`" class="d-block ui-w-40 rounded-circle" alt>
              <div class="media-body ml-3">
                <div class="text-dark line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                <div class="text-light small mt-1">
                  Mae Gibson &nbsp;·&nbsp; 58m ago
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
              <img :src="`${baseUrl}img/avatars/4-small.png`" class="d-block ui-w-40 rounded-circle" alt>
              <div class="media-body ml-3">
                <div class="text-dark line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>
                <div class="text-light small mt-1">
                  Kenneth Frazier &nbsp;·&nbsp; 1h ago
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
              <img :src="`${baseUrl}img/avatars/5-small.png`" class="d-block ui-w-40 rounded-circle" alt>
              <div class="media-body ml-3">
                <div class="text-dark line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                <div class="text-light small mt-1">
                  Nelle Maxwell &nbsp;·&nbsp; 2h ago
                </div>
              </div>
            </b-list-group-item>

            <b-list-group-item href="javascript:void(0)" class="media d-flex align-items-center">
              <img :src="`${baseUrl}img/avatars/11-small.png`" class="d-block ui-w-40 rounded-circle" alt>
              <div class="media-body ml-3">
                <div class="text-dark line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>
                <div class="text-light small mt-1">
                  Belle Ross &nbsp;·&nbsp; 5h ago
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>

          <a href="javascript:void(0)" class="d-block text-center text-light small p-2 my-1">Show all messages</a>
        </b-nav-item-dropdown> -->

        <!-- Divider -->
        <!-- <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div> -->

        <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <!-- <img v-if="this.$store.state.user.avatar === null" 
              :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle" width="30" height="30"> -->
              <div>
                <!-- <i class="ion d-block ion-ios-contact display-4 text-primary"></i> -->
              </div>
              <!-- <img v-else :src="this.$store.state.user.avatar" alt class="d-block ui-w-30 rounded-circle"> -->
              
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ this.$store.state.user.name}}</span>
            </span>
          </template>

          <!-- <b-dd-item><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item> -->
          <b-dd-item to="/account-settings"><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-divider />
          <b-dd-item :to="{name:'Logout'}"><i class="ion ion-ios-log-out text-primary"></i> &nbsp; Sign out</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

    <!-- vue-notification -->
    
      <offline @detected-condition="handleConnectivityChange" style="display:none">
        <div slot="offline">
          <p>You appear to be offline, that's okay, we can still do things...</p>
        </div>
      </offline> 
   
    <!-- vue-notification -->

    <!-- CuiVueBlockui -->
    <BlockUI :html="html" v-if="isBusy"/>
    <!-- CuiVueBlockui -->

</div>  
</template>

<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-toasted/vue-toasted.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<!-- Custom template animation -->
<style>
.material-style .sidenav .app-brand.demo {
    height: 71px;
}

.loading-container .loading-backdrop {
  background: #fff !important;
  opacity: .7 !important;
}
.loading-container .loading {
  background: none !important;
  box-shadow: none !important;
}

.ui-w-30 {
    width: 30px !important;
    height: 30px !important;
}
  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all .5s;
  }
  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }

.badge.indicator:not(.badge-dot) {
    padding: .3em .4em;
    font-size: .6em;
    line-height: 1.2em;
    -webkit-transform: translate(-50%, -10%);
    transform: translate(-50%, -10%);
}

/* #search-bar::-webkit-input-placeholder { 
  color: #fff !important;
}
#search-bar::-moz-placeholder {
  color: #fff !important;
}
#search-bar:-ms-input-placeholder {
  color: #fff !important;
}
#search-bar:-moz-placeholder { 
  color: #fff !important;
} */

</style>

<script>

import Vue from 'vue'

import moment from 'moment';
import jwt_decode from 'jwt-decode'

import axios from 'axios';

import offline from 'v-offline'

import BlockUI from 'vue-blockui'
Vue.use(BlockUI)

import Toasted from 'vue-toasted'
Vue.use(Toasted)

import Notifications from 'vue-notification'
Vue.use(Notifications)

export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  components:{
    offline
  },

  data () {
    return{
      userId: null,
      profile: {},
      unreadNotificationsCount: 0,
      unreadMessageCount: 0,
      // unreadNotifications:[],
      notifications :[],
      newNotification:{
        data:{
          subject: null,
          content: null,
        },
        created_at: null,
        updated_at: null,
        read_at: null,
      },
      moment:moment,
      // status:null,
      //
    // vue-toasted
    //

    toastText: '',
    toastActions: false,

    toastStyle: 'outline',
    toastStyles: [
      { text: 'Default', value: 'primary' },
      { text: 'Bubble', value: 'bubble' },
      { text: 'Outline', value: 'outline' }
    ],

    toastVerticalPosition: 'top',
    toastVerticalPositions: [
      { text: 'Top', value: 'top' },
      { text: 'Bottom', value: 'bottom' }
    ],

    toastHorizontalPosition: 'center',
    toastHorizontalPositions: [
      { text: 'Left', value: 'left' },
      { text: 'Center', value: 'center' },
      { text: 'Right', value: 'right' }
    ],

    toastVariant: 'success',
    toastVariants: [
      { text: 'None', value: null },
      { text: 'Primary', value: 'primary' },
      { text: 'Secondary', value: 'secondary' },
      { text: 'Success', value: 'success' },
      { text: 'Warning', value: 'warning' },
      { text: 'Info', value: 'info' },
      { text: 'Danger', value: 'danger' },
      { text: 'Dark', value: 'dark' }
    ],

    keywords:'',
    isBusy:false,
      // html:null,
      html: `
      <div class="sk-wave sk-primary">
        <div class="sk-rect sk-rect1"></div>
              <div class="sk-rect sk-rect2"></div>
              <div class="sk-rect sk-rect3"></div>
              <div class="sk-rect sk-rect4"></div>
              <div class="sk-rect sk-rect5"></div>    
        </div>
      <h5 class="text-center mb-0">Searching...</h5>`,
    
    }
  },

  computed:{
    notificationToDisplay () {
      // return this.unreadNotifications.slice(0,5);
      // return this.notifications;
       return this.$store.state.notifications;
    }
  },

  mounted () {

  },

  created () {
    this.getProfile();
    this.getNotification();
    this.listen();
  },

  watch: {
    // call again the method if the route changes
    '$route': 'getProfile',
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },

    // Listen
    listen () {
      if(localStorage.getItem('token')){
        let decoded = jwt_decode(localStorage.getItem('token'));
        window.Echo.private('App.Models.User.' + decoded.sub)
        .notification((notification) => { 
          this.newNotification.data.subject = notification.subject;
          this.newNotification.data.content = notification.content;
          this.newNotification.created_at = notification.created_at.date;
          this.newNotification.updated_at = notification.updated_at.date;
          this.toastText = this.newNotification.data.content;
          this.showToast();
          // this.unreadNotifications.unshift(this.newNotification);
          // this.notifications.unshift(this.newNotification);
          this.getNotification();
          this.unreadNotificationsCount++;
          this.$store.dispatch('getDashboardData');
        });       

      }
    },

    getProfile () {
      axios({
        method:'get',
        url:'/api/profile',
      }).then( res => {
        const user = res.data.profile;
        this.$store.commit('authSuccess', {user});
        this.profile = res.data.profile;
        this.userId = res.data.profile.id;
      })
    },

    getNotification () {
      axios({
        method:'get',
        url:'/api/notifications',
      }).then(res =>{
        // this.unreadNotifications = res.data.unreadNotifications;
        // this.unreadCount = this.unreadNotifications.length;
        this.unreadNotificationsCount = res.data.unreadNotifications;
        this.notifications = res.data.notifications;
        this.$store.state.notifications = res.data.notifications;
        this.$store.state.unreadNotificationsCount = res.data.unreadNotifications;
      })
    },

    showAllNotification () { 
      axios({
        method: 'post',
        url: '/api/notifications/makeRead',
      }).then(res =>{
        this.getNotification();
        this.$router.push({name:'Notifications'})
      })
    },

    makeNotificationsRead () { 
      // alert('hello');
      axios({
        method: 'post',
        url: '/api/notifications/makeRead',
      }).then(res =>{
        this.getNotification();
      })
    },

    //
    // vue-toasted
    //

    getCustomClasses () {
      if (!this.toastVariant) return null

      if (this.toastStyle !== 'outline') {
        return `bg-${this.toastVariant} text-${this.toastVariant === 'warning' ? 'dark' : 'white'}`
      } else {
        return `border-${this.toastVariant} text-${this.toastVariant}`
      }
    },
    getActionCustomClasses () {
      if (!this.toastVariant) return null

      if (this.toastStyle !== 'outline') {
        return `opacity-75 text-${this.toastVariant === 'warning' ? 'dark' : 'white'}`
      } else {
        return `opacity-75 text-${this.toastVariant}`
      }
    },

    showToast () {
      const actions = [{
        text: 'Cancel',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
        class: this.getActionCustomClasses()
      }, {
        text: 'Undo',
        push: {
          name: 'somewhere',
          dontClose: true
        },
        class: this.getActionCustomClasses()
      }]

      this.$toasted.show(this.toastText, {
        theme: this.toastStyle,
        position: `${this.toastVerticalPosition}-${this.toastHorizontalPosition}`,
        action: this.toastActions ? actions : null,
        icon: this.toastIcon || null,
        className: this.getCustomClasses(),
        duration: 4000
      })
    },


    generalSearch () {
        var keywords = this.keywords
        this.$store.state.jsonData = [];
        this.$store.state.originalJsonData = [];
        this.$store.dispatch('searchVehicleAction', keywords );
        // this.$router.push({name:'SearchResult'})
        this.isBusy = true;
        setTimeout(() => {
          this.isBusy = false;
          this.$router.push({ name: 'SearchResult' })
        }, 1000);

    },

    // Check internet connection
    handleConnectivityChange (status) {
      var message = '';  
      if (status) {
        message = "It looks like you're online! Here's all the things you can do..."
        this.toastVariant = 'success'
      }else{
        message = "No internet connection."
        this.toastVariant = 'danger'
      }
      this.toastText = message;
      this.showToast();
    },

    goTo (notification) {
      if(notification.data.notification_type === 'invoice'){
        this.$refs.navDropdownNotification.hide();
        this.$router.push({name:'printInvoice', params:{id:notification.data.invoiceId}});
      } else if(notification.data.notification_type === 'photo'){
        this.$refs.navDropdownNotification.hide();
        this.$router.push({name:'Album', params:{id:notification.data.carId}});
      } else if(notification.data.notification_type === 'document'){
        this.$refs.navDropdownNotification.hide();
        this.$router.push({name:'Docs', params:{id:notification.data.carId}});  
      } else if(notification.data.notification_type === 'container'){
        this.$refs.navDropdownNotification.hide();
        this.$router.push({name:'Container', params:{id:notification.data.containerId}});    
      } else{
        this.$refs.navDropdownNotification.hide();
        this.$router.push({name:'VehicleDetails', params:{id:notification.data.carId}});
      }

      axios({
        method: 'post',
        url:'/api/notifications/singleMakeRead/'+notification.id,
      }).then( res => {
        this.getNotification()
      })
    }
  }
}
</script>
