import LayoutBlank from '@/layout/LayoutBlank'

export default [{
    path:'/prints',
    component:LayoutBlank,
    meta:{requiresAuth:true},
    children:[
        {
            path: 'invoice/:id',
            component: () => import('@/components/invoices/PrintInvoice'),
            name: 'printInvoice',
            props: true
        }
    ]
}]
